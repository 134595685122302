<template>
  <div>
    <div :class="getButtonType()">
      <p v-html="msg" :class="buttonType"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoodleButton',
  props: {
    msg: String,
    buttonType: String,
    clickable: Boolean,
  },
  methods:{
    getButtonType(){
      if(this.clickable){
        return 'is-outer-button is-clickable'
      }else{
        return 'is-outer-button'
      }
    } 
  }
}
</script>

<style scoped>

@import '../assets/roboto.css';
.is-outer-button {
  border: 2px solid white;
  color: white;
  font-family:  'Roboto',Helvetica,Arial,sans-serif;
  /*margin-left: 3em;
  width: 356px;
  height: 132px;*/
  margin-left: auto;
  margin-right: auto;
  width: 290px;
  height: 140px;
}

.is-small-button {
  font-weight: bolder;
  font-size: 18pt;
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.is-large-button {
  font-weight: bolder;
  font-size: 24pt;
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.is-clickable:hover{
  color: #00a490;
  border: 2px solid #00a490;
}
</style>