<template>
  <div class="main-page">
    <div class="is-top-bar"></div>
    <div class="columns is-centered">
      <div class="column is-2">
        <a href="https://fcsh.unl.pt" target="_blank">
          <img class="is-fcsh-logo" alt="FCSH" src="../assets/fcsh.svg">
        </a>
      </div>
    </div>
    <div class="is-medium-bar" ></div>
    <div class="is-center-image">
      <div class="is-main-text">
        <div class="columns is-centered is-welcome">
          <div class="column">
            <img class="is-fcsh-logo" alt="Moodle" src="../assets/moodle.svg">
            <p class="title is-presentation-text">Bem-vindos(as) à plataforma de ensino digital da NOVA FCSH</p>
            <p class="title is-presentation-text-warning">A versão atual desta plataforma será descontinuada no dia 4 de setembro</p>
            <p class="title is-presentation-text-warning">Se for docente e pretender migrar as suas cadeiras envie um email para apoiomoodle@fcsh.unl.pt</p>
          </div>
        </div>
        <div class="columns is-centered is-button-row">
          <div class="column is-3">
            <a href="https://elearning.fcsh.unl.pt/acient">
              <moodle-button class="is-top-button" :msg="'Cursos'" :button-type="'is-large-button'" :clickable="true"></moodle-button>
            </a>
          </div>
          <div class="column is-3">
            <a href="https://elearning.fcsh.unl.pt/flv">
              <moodle-button class="is-bottom-button" :msg="'Formação<br/> ao <br/> Longo da Vida'" :button-type="'is-large-button'" :clickable="true"></moodle-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoodleButton from './MoodleButton.vue'
export default {
  components: { MoodleButton },
  name: 'AltView',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/roboto.css';
@import '../assets/montserrat.css';
.is-top-bar {
  margin-top: 0;
  background: #00a490;
  height: 2em;
  width: 100%;
}

.is-fcsh-logo {
  margin-top: 1em;
}


.is-medium-bar {
  background: #0594c4;
  height: 3em;
  width: 100%;
}

.is-center-image {
  background-image: url("../assets/background.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.is-main-text {
  height: 700px;
}

@media screen and (max-width: 700px) {
  .is-main-text {
    height: 60%;
  }

  .is-welcome {
    display:none;
  }

  .is-fcsh-logo {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .is-bottom-button {
    margin-top: 2em;
  }

  .is-top-button {
    margin-top: 5em;
  }
}


.is-moodle-logo {
  margin-left: 3em;
  margin-bottom: 1em;
  height: 2em;
}

.is-button-row {
  margin: 0;
  position: relative;
  top: 10%;
  -ms-transform: translateY(-10%);
  transform: translateY(-10%);
}

.is-presentation-text {
  color: white;
  font-family:  'Montserrat', Helvetica, Arial, sans-serif;
  margin-top: 2em;
}

.is-presentation-text-warning {
  color: red;
  font-family:  'Montserrat', Helvetica, Arial, sans-serif;
  margin-top: 2em;
}
</style>
